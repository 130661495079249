div.tags-bar {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  div.chip {
    background: var(--ion-color-light);
    color: var(--ion-color-light-contrast);
    border: 1px solid #ebebeb;
    border-radius: 30px;
    padding: 6px 15px;
    /* max-width: 220px;
    overflow: hidden; */
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
    text-align: center;
  }