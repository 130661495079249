

    @media only screen and (max-width: 340px) {
        .logo-image {
            width: 50px;
        }
    }

    @media only screen and (min-width: 340px) {
        .logo-image {
            width: 70px;
        }
    }
