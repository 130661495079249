@font-face {
    font-family: "Orbitron";
    src: url("./assets/fonts/Orbitron/Orbitron-Black.ttf") format("truetype");
    src: url("./assets/fonts/Orbitron/Orbitron-Regular.ttf") format("truetype");
}

 /* ion-content {
 background-color: #2c2f38 !important;
  --background: #2c2f38 !important;
  
}
 */

 ion-split-pane {
   --side-width: 290px;
   --side-min-width: 220px;
   --side-max-width: 290px;
 }

div.ion-page ion-header {
  margin-top: 75px !important;
}
/*
div.ion-page {
   margin-bottom: 60px !important; 
}
*/
/*
 cool blue #d0edf1

*/

.max-lines-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.max-lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.max-lines-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.max-lines-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.max-lines-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.max-lines-7 {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.5s;
}
.ripple:hover {
  background:white radial-gradient(circle, transparent 1%, rgb(243, 243, 243) 1%) center/15000%;
}
.ripple:active {
  background-color: rgb(245, 245, 245);
  background-size: 100%;
  transition: background 0s;
}
/* 
body {
 background-color: #3a3a3a; 
} */

.basic-badge {
    border: 1px solid var(--ion-color-lightest) !important;
    background-color: var(--ion-color-lightest) !important;
    color: var(--ion-color-lightest-contrast) !important;
    font-size: 12px;
    border-radius: 5px;
    padding: 3px 6px;
    display: table;
  }
  
  .pro-badge {
    border: 1px solid var(--ion-color-pro-badge) !important;
    background-color: var(--ion-color-pro-badge) !important;
    color: var(--ion-color-pro-badge-contrast) !important;
    font-size: 12px;
    border-radius: 5px;
    padding: 3px 6px;
    display: table;
  }
  
  .pro-badge ion-icon {
    color: #ebc700;
    font-size: 90%;
    padding-right: 3px;
  }


  :root {

    /* --ion-color-header: #1e3769;
    --ion-color-header-rgb: 30,55,105;
    --ion-color-header-contrast: #ffffff;
    --ion-color-header-contrast-rgb: 255,255,255;
    --ion-color-header-shade: #1a305c;
    --ion-color-header-tint: #354b78; */
  
    --ion-color-ion-content-bg:  #e7e7e7;
    --ion-color-ion-content-bg-rgb: 230,230,230;
    --ion-color-ion-content-bg-contrast: #000000;
    --ion-color-ion-content-bg-contrast-rgb: 0,0,0;
    --ion-color-ion-content-bg-shade: #d8d8d8;
    --ion-color-ion-content-bg-tint: #f3f3f3;

    --ion-color-bg: #dfdfdf; /*#f1f1f1;*/
    --ion-color-bg-rgb: 241,241,241;
    --ion-color-bg-contrast: #000000;
    --ion-color-bg-contrast-rgb: 0,0,0;
    --ion-color-bg-shade: #d4d4d4;
    --ion-color-bg-tint: #f2f2f2;
  
    /* --ion-color-header: #3452bd;
    --ion-color-header-rgb: 52,82,189;
    --ion-color-header-contrast: #ffffff;
    --ion-color-header-contrast-rgb: 255,255,255;
    --ion-color-header-shade: #2e48a6;
    --ion-color-header-tint: #4863c4; */
  
    /* --ion-color-header: #4b6eea;
    --ion-color-header-rgb: 75,110,234;
    --ion-color-header-contrast: #ffffff;
    --ion-color-header-contrast-rgb: 255,255,255;
    --ion-color-header-shade: #4261ce;
    --ion-color-header-tint: #5d7dec; */
  
    --ion-color-header: #2b48b3;
    --ion-color-header-rgb: 75,110,234;
    --ion-color-header-contrast: #ffffff;
    --ion-color-header-contrast-rgb: 255,255,255;
    --ion-color-header-shade: #4261ce;
    --ion-color-header-tint: #5d7dec;

    --ion-color-header-separator: #162952;
  --ion-color-header-separator-rgb: 35,49,78;
  --ion-color-header-separator-contrast: #ffffff;
  --ion-color-header-separator-contrast-rgb: 255,255,255;
  --ion-color-header-separator-shade: #1f2b45;
  --ion-color-header-separator-tint: #394660;
  
    --ion-color-primary: #2b48b3;
    --ion-color-primary-rgb: 75,110,234;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #4261ce;
    --ion-color-primary-tint: #5d7dec;
  
    --ion-color-secondary: #ff002f;
    --ion-color-secondary-rgb: 255,0,47;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #e00029;
    --ion-color-secondary-tint: #ff1a44;
  
    --ion-color-white: #FFFFFF;
    --ion-color-white-rgb: 255,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;
  
    --ion-color-black: rgb(0, 0, 0);
    --ion-color-black-rgb: 0, 0, 0;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255, 255, 255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #000000;
  
    --ion-color-lightest: #E0E0E0;
    --ion-color-lightest-rgb: 224,224,224;
    --ion-color-lightest-contrast: #000000;
    --ion-color-lightest-contrast-rgb: 0,0,0;
    --ion-color-lightest-shade: #c5c5c5;
    --ion-color-lightest-tint: #e3e3e3;
  
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244,245,248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  
    --ion-color-card-bg: #727272;
    --ion-color-card-bg-rgb: 222,222,230;
    --ion-color-card-bg-contrast: #000000;
    --ion-color-card-bg-contrast-rgb: 0,0,0;
    --ion-color-card-bg-shade: #c3c3ca;
    --ion-color-card-bg-tint: #e1e1e9;
  
    --ion-color-medium: #616161;
    --ion-color-medium-rgb: 230,230,230;
    --ion-color-medium-contrast: #FFFFFF;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #3a3a3a;
    --ion-color-medium-tint: #999999;
  
    --ion-color-highlight: #edcc13;
    --ion-color-highlight-rgb: 237,204,19;
    --ion-color-highlight-contrast: #000000;
    --ion-color-highlight-contrast-rgb: 0,0,0;
    --ion-color-highlight-shade: #d1b411;
    --ion-color-highlight-tint: #efd12b;
  
    --ion-color-pro-badge: #2b48b3;
    --ion-color-pro-badge-rgb: 75,110,234;
    --ion-color-pro-badge-contrast: #ffffff;
    --ion-color-pro-badge-contrast-rgb: 255,255,255;
    --ion-color-pro-badge-shade: #4261ce;
    --ion-color-pro-badge-tint: #5d7dec;
  }
  
  .ion-color-white {
    --ion-color-base: var(--ion-color-pr);
    --ion-color-base-rgb: var(--ion-color-pr-rgb);
    --ion-color-contrast: var(--ion-color-pr-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pr-contrast-rgb);
    --ion-color-shade: var(--ion-color-pr-shade);
    --ion-color-tint: var(--ion-color-pr-tint);
  }
  
  .ion-color-black {
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
  }
  
  .ion-color-card-bg {
    --ion-color-base: var(--ion-color-card-bg);
    --ion-color-base-rgb: var(--ion-color-card-bg-rgb);
    --ion-color-contrast: var(--ion-color-card-bg-contrast);
    --ion-color-contrast-rgb: var(--ion-color-card-bg-contrast-rgb);
    --ion-color-shade: var(--ion-color-card-bg-shade);
    --ion-color-tint: var(--ion-color-card-bg-tint);
  }
  
  :root {
    --ion-color-inputs: #f1f1f1;
    --ion-color-inputs-rgb: 241,241,241;
    --ion-color-inputs-contrast: #000000;
    --ion-color-inputs-contrast-rgb: 0,0,0;
    --ion-color-inputs-shade: #d4d4d4;
    --ion-color-inputs-tint: #f2f2f2;
  }
  
  .ion-color-inputs {
    --ion-color-base: var(--ion-color-inputs);
    --ion-color-base-rgb: var(--ion-color-inputs-rgb);
    --ion-color-contrast: var(--ion-color-inputs-contrast);
    --ion-color-contrast-rgb: var(--ion-color-inputs-contrast-rgb);
    --ion-color-shade: var(--ion-color-inputs-shade);
    --ion-color-tint: var(--ion-color-inputs-tint);
  }
  
  
  /* 
  :root {
    --ion-color-primary: #323031;
    --ion-color-primary-rgb: 50,48,49;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #2c2a2b;
    --ion-color-primary-tint: #474546;
  
    --ion-color-prim: #2f2af2;
    --ion-color-prim-rgb: 47,42,242;
    --ion-color-prim-contrast: #ffffff;
    --ion-color-prim-contrast-rgb: 255,255,255;
    --ion-color-prim-shade: #2925d5;
    --ion-color-prim-tint: #443ff3;
  
    --ion-color-secondary: #7F7979;
    --ion-color-secondary-rgb: 127,121,121;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #706a6a;
    --ion-color-secondary-tint: #8c8686;
  
    --ion-color-tertiary: #5F5B6B;
    --ion-color-tertiary-rgb: 95,91,107;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #54505e;
    --ion-color-tertiary-tint: #6f6b7a;
  
    --ion-color-light: #C1BDB3;
    --ion-color-light-rgb: 193,189,179;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #aaa69e;
    --ion-color-light-tint: #c7c4bb;
  
    --ion-color-primaryoffset: #3D3B3C;
    --ion-color-primaryoffset-rgb: 61,59,60;
    --ion-color-primaryoffset-contrast: #ffffff;
    --ion-color-primaryoffset-contrast-rgb: 255,255,255;
    --ion-color-primaryoffset-shade: #363435;
    --ion-color-primaryoffset-tint: #504f50;
  
    --ion-color-pri: #ffdf23;
    --ion-color-pri-rgb: 255,223,35;
    --ion-color-pri-contrast: #000000;
    --ion-color-pri-contrast-rgb: 0,0,0;
    --ion-color-pri-shade: #e0c41f;
    --ion-color-pri-tint: #ffe239;
  
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16,220,96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;
  
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255,206,0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;
  
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245,61,61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;
  
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34,34,34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
  
  }
  
  :root {
    --ion-color-btn: #aa32f1;
    --ion-color-btn-rgb: 170,50,241;
    --ion-color-btn-contrast: #ffffff;
    --ion-color-btn-contrast-rgb: 255,255,255;
    --ion-color-btn-shade: #962cd4;
    --ion-color-btn-tint: #b347f2;
  }
  
  .ion-color-prim {
    --ion-color-base: var(--ion-color-prim);
    --ion-color-base-rgb: var(--ion-color-prim-rgb);
    --ion-color-contrast: var(--ion-color-prim-contrast);
    --ion-color-contrast-rgb: var(--ion-color-prim-contrast-rgb);
    --ion-color-shade: var(--ion-color-prim-shade);
    --ion-color-tint: var(--ion-color-prim-tint);
  }
  
  :root {
    --ion-color-sec: #f64c72;
    --ion-color-sec-rgb: 246,76,114;
    --ion-color-sec-contrast: #000000;
    --ion-color-sec-contrast-rgb: 0,0,0;
    --ion-color-sec-shade: #d84364;
    --ion-color-sec-tint: #f75e80;
  }
  
  .ion-color-sec {
    --ion-color-base: var(--ion-color-sec);
    --ion-color-base-rgb: var(--ion-color-sec-rgb);
    --ion-color-contrast: var(--ion-color-sec-contrast);
    --ion-color-contrast-rgb: var(--ion-color-sec-contrast-rgb);
    --ion-color-shade: var(--ion-color-sec-shade);
    --ion-color-tint: var(--ion-color-sec-tint);
  }
  
  :root {
    --ion-color-bg: #121241;
    --ion-color-bg-rgb: 18,18,65;
    --ion-color-bg-contrast: #ffffff;
    --ion-color-bg-contrast-rgb: 255,255,255;
    --ion-color-bg-shade: #101039;
    --ion-color-bg-tint: #2a2a54;
  }
  
  .ion-color-bg {
    --ion-color-base: var(--ion-color-bg);
    --ion-color-base-rgb: var(--ion-color-bg-rgb);
    --ion-color-contrast: var(--ion-color-bg-contrast);
    --ion-color-contrast-rgb: var(--ion-color-bg-contrast-rgb);
    --ion-color-shade: var(--ion-color-bg-shade);
    --ion-color-tint: var(--ion-color-bg-tint);
  }
  
  :root {
    --ion-color-main: #2f2fa2;
    --ion-color-main-rgb: 47,47,162;
    --ion-color-main-contrast: #ffffff;
    --ion-color-main-contrast-rgb: 255,255,255;
    --ion-color-main-shade: #29298f;
    --ion-color-main-tint: #4444ab;
  }
  
  .ion-color-main {
    --ion-color-base: var(--ion-color-main);
    --ion-color-base-rgb: var(--ion-color-main-rgb);
    --ion-color-contrast: var(--ion-color-main-contrast);
    --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
    --ion-color-shade: var(--ion-color-main-shade);
    --ion-color-tint: var(--ion-color-main-tint);
  }
  
  .ion-color-btn {
    --ion-color-base: var(--ion-color-btn);
    --ion-color-base-rgb: var(--ion-color-btn-rgb);
    --ion-color-contrast: var(--ion-color-btn-contrast);
    --ion-color-contrast-rgb: var(--ion-color-btn-contrast-rgb);
    --ion-color-shade: var(--ion-color-btn-shade);
    --ion-color-tint: var(--ion-color-btn-tint);
  }
  
  :root {
    --ion-color-light-bg: #E3E2E1;
    --ion-color-light-bg-rgb: 227,226,225;
    --ion-color-light-bg-contrast: #000000;
    --ion-color-light-bg-contrast-rgb: 0,0,0;
    --ion-color-light-bg-shade: #c8c7c6;
    --ion-color-light-bg-tint: #e6e5e4;
  }
  
  .ion-color-light-bg {
    --ion-color-base: var(--ion-color-light-bg);
    --ion-color-base-rgb: var(--ion-color-light-bg-rgb);
    --ion-color-contrast: var(--ion-color-light-bg-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-bg-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-bg-shade);
    --ion-color-tint: var(--ion-color-light-bg-tint);
  }
  
  :root {
    --ion-color-primary-color: #202E48;
    --ion-color-primary-color-rgb: 32,46,72;
    --ion-color-primary-color-contrast: #ffffff;
    --ion-color-primary-color-contrast-rgb: 255,255,255;
    --ion-color-primary-color-shade: #1c283f;
    --ion-color-primary-color-tint: #36435a;
  }
  
  .ion-color-primary-color {
    --ion-color-base: var(--ion-color-primary-color);
    --ion-color-base-rgb: var(--ion-color-primary-color-rgb);
    --ion-color-contrast: var(--ion-color-primary-color-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-color-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-color-shade);
    --ion-color-tint: var(--ion-color-primary-color-tint);
  } */
  
  /*
   * Dark Colors
   * -------------------------------------------
   */
  
   body.dark ion-card {
     --background: rgb(44, 44, 44);
   }
  
   /* body.dark ion-button.emoticon img {
    border-radius: 50%;
    border: 1px solid rgb(88, 99, 112);
   } */
  
   body.dark {
    /* --ion-color-primary: #7F7979;
    --ion-color-primary-rgb: 127,121,121;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #706a6a;
    --ion-color-primary-tint: #8c8686; */
    /* --ion-color-primary: #4d76ff;
    --ion-color-primary-rgb: 77,118,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #4468e0;
    --ion-color-primary-tint: #5f84ff; */
  
    --ion-color-header: #424242;
    --ion-color-header-rgb: 66,66,66;
    --ion-color-header-contrast: #ffffff;
    --ion-color-header-contrast-rgb: 255,255,255;
    --ion-color-header-shade: #3a3a3a;
    --ion-color-header-tint: #555555;

    --ion-color-header-separator: #23314e;
  --ion-color-header-separator-rgb: 35,49,78;
  --ion-color-header-separator-contrast: #ffffff;
  --ion-color-header-separator-contrast-rgb: 255,255,255;
  --ion-color-header-separator-shade: #1f2b45;
  --ion-color-header-separator-tint: #394660;
  
    --ion-color-primary: #c7c7c7;
    --ion-color-primary-rgb: 199,199,199;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #afafaf;
    --ion-color-primary-tint: #cdcdcd;
  
    --ion-color-secondary: #ff002f;
    --ion-color-secondary-rgb: 255,0,47;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #e00029;
    --ion-color-secondary-tint: #ff1a44;
  
      --ion-color-white: #000000;
      --ion-color-white-rgb: 0,0,0;
      --ion-color-white-contrast: #dfdfdf;
      --ion-color-white-contrast-rgb: 255,255,255;
      --ion-color-white-shade: #000000;
      --ion-color-white-tint: #1a1a1a;
    
  
      --ion-color-pr: #7F7979;
      --ion-color-pr-rgb: 75,123,245;
      --ion-color-pr-contrast: #FFF;
      --ion-color-pr-contrast-rgb: 255,255,255;
      --ion-color-pr-shade: #426cd8;
      --ion-color-pr-tint: #5d88f6;
  
      --ion-color-pri: #ffdf23;
      --ion-color-pri-rgb: 255,223,35;
      --ion-color-pri-contrast: #181829;
      --ion-color-pri-contrast-rgb: 0,0,0;
      --ion-color-pri-shade: #e0c41f;
      --ion-color-pri-tint: #ffe239;
    
  /* 
    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff; */
  
    --ion-color-tertiary: #5a99fd;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
  
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
  
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
  
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
  
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
  
    --ion-color-medium: #b8b8b8;
    --ion-color-medium-rgb: 184,184,184;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #a2a2a2;
    --ion-color-medium-tint: #bfbfbf;
  
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  
    --ion-color-lightest: #1e1f24;
    --ion-color-lightest-rgb: 30,31,36;
    --ion-color-lightest-contrast: #cacaca;
    --ion-color-lightest-contrast-rgb: 255,255,255;
    --ion-color-lightest-shade: #1a1b20;
    --ion-color-lightest-tint: #35353a;
  
   --ion-color-inputs: #393939;
    --ion-color-inputs-rgb: 57,57,57;
    --ion-color-inputs-contrast: #ffffff;
    --ion-color-inputs-contrast-rgb: 255,255,255;
    --ion-color-inputs-shade: #323232;
    --ion-color-inputs-tint: #4d4d4d;
    
  
  
    --ion-color-card-bg: #3c3c3c;
    --ion-color-card-bg-rgb: 60,60,60;
    --ion-color-card-bg-contrast: #ffffff;
    --ion-color-card-bg-contrast-rgb: 255,255,255;
    --ion-color-card-bg-shade: #353535;
    --ion-color-card-bg-tint: #505050;

    --ion-color-bg: #0d0d0d;
  --ion-color-bg-rgb: 13,13,13;
  --ion-color-bg-contrast: #ffffff;
  --ion-color-bg-contrast-rgb: 255,255,255;
  --ion-color-bg-shade: #0b0b0b;
  --ion-color-bg-tint: #252525;
  
  
      --ion-color-btn: #aa32f1;
      --ion-color-btn-rgb: 170,50,241;
      --ion-color-btn-contrast: #ffffff;
      --ion-color-btn-contrast-rgb: 255,255,255;
      --ion-color-btn-shade: #962cd4;
      --ion-color-btn-tint: #b347f2;
    
  
      --ion-color-ion-content-bg: #505050;
      --ion-color-ion-content-bg-rgb: 111,111,111;
      --ion-color-ion-content-bg-contrast: #ffffff;
      --ion-color-ion-content-bg-contrast-rgb: 255,255,255;
      --ion-color-ion-content-bg-shade: #333333;
      --ion-color-ion-content-bg-tint: #7d7d7d;
    
      --ion-color-highlight: #edcc13;
      --ion-color-highlight-rgb: 237,204,19;
      --ion-color-highlight-contrast: #000000;
      --ion-color-highlight-contrast-rgb: 0,0,0;
      --ion-color-highlight-shade: #d1b411;
      --ion-color-highlight-tint: #efd12b;
    
      --ion-color-pro-badge: #2b48b3;
      --ion-color-pro-badge-rgb: 75,110,234;
      --ion-color-pro-badge-contrast: #ffffff;
      --ion-color-pro-badge-contrast-rgb: 255,255,255;
      --ion-color-pro-badge-shade: #4261ce;
      --ion-color-pro-badge-tint: #5d7dec;
  }
  
  /*
   * iOS Dark Theme
   * -------------------------------------------
   */
  
  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;
  
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;
  
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
  
    --ion-toolbar-background: #0d0d0d;
  
    --ion-item-background: #1c1c1c;
    --ion-item-background-activated: #313131;
  }
  
  
  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
  
  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;
  
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;
  
    --ion-border-color: #222222;
  
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
  
    --ion-item-background: #1A1B1E;
  }
  
  .ion-color-white {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
  }
  
  .ion-color-header {
    --ion-color-base: var(--ion-color-header);
    --ion-color-base-rgb: var(--ion-color-header-rgb);
    --ion-color-contrast: var(--ion-color-header-contrast);
    --ion-color-contrast-rgb: var(--ion-color-header-contrast-rgb);
    --ion-color-shade: var(--ion-color-header-shade);
    --ion-color-tint: var(--ion-color-header-tint);
  }
  
  :root {
    --ion-color-question-header: #22b50d;
    --ion-color-question-header-rgb: 34,181,13;
    --ion-color-question-header-contrast: #ffffff;
    --ion-color-question-header-contrast-rgb: 255,255,255;
    --ion-color-question-header-shade: #1e9f0b;
    --ion-color-question-header-tint: #38bc25;
  }
  
  .ion-color-question-header {
    --ion-color-base: var(--ion-color-question-header);
    --ion-color-base-rgb: var(--ion-color-question-header-rgb);
    --ion-color-contrast: var(--ion-color-question-header-contrast);
    --ion-color-contrast-rgb: var(--ion-color-question-header-contrast-rgb);
    --ion-color-shade: var(--ion-color-question-header-shade);
    --ion-color-tint: var(--ion-color-question-header-tint);
  }
  :root {
    --ion-color-parts-for-sale-header: #9532d6;
    --ion-color-parts-for-sale-header-rgb: 149,50,214;
    --ion-color-parts-for-sale-header-contrast: #ffffff;
    --ion-color-parts-for-sale-header-contrast-rgb: 255,255,255;
    --ion-color-parts-for-sale-header-shade: #832cbc;
    --ion-color-parts-for-sale-header-tint: #a047da;
  }
  
  .ion-color-parts-for-sale-header {
    --ion-color-base: var(--ion-color-parts-for-sale-header);
    --ion-color-base-rgb: var(--ion-color-parts-for-sale-header-rgb);
    --ion-color-contrast: var(--ion-color-parts-for-sale-header-contrast);
    --ion-color-contrast-rgb: var(--ion-color-parts-for-sale-header-contrast-rgb);
    --ion-color-shade: var(--ion-color-parts-for-sale-header-shade);
    --ion-color-tint: var(--ion-color-parts-for-sale-header-tint);
  }
  
  :root {
    --ion-color-vehicle-for-sale-header: #00a9fd;
    --ion-color-vehicle-for-sale-header-rgb: 0,169,253;
    --ion-color-vehicle-for-sale-header-contrast: #FFFFFF;
    --ion-color-vehicle-for-sale-header-contrast-rgb: 255,255,255;
    --ion-color-vehicle-for-sale-header-shade: #0095df;
    --ion-color-vehicle-for-sale-header-tint: #1ab2fd;
  }
  
  .ion-color-vehicle-for-sale-header {
    --ion-color-base: var(--ion-color-vehicle-for-sale-header);
    --ion-color-base-rgb: var(--ion-color-vehicle-for-sale-header-rgb);
    --ion-color-contrast: var(--ion-color-vehicle-for-sale-header-contrast);
    --ion-color-contrast-rgb: var(--ion-color-vehicle-for-sale-header-contrast-rgb);
    --ion-color-shade: var(--ion-color-vehicle-for-sale-header-shade);
    --ion-color-tint: var(--ion-color-vehicle-for-sale-header-tint);
  }
  
  :root {
    --ion-color-poll-header: #d4230b;
    --ion-color-poll-header-rgb: 212,35,11;
    --ion-color-poll-header-contrast: #ffffff;
    --ion-color-poll-header-contrast-rgb: 255,255,255;
    --ion-color-poll-header-shade: #bb1f0a;
    --ion-color-poll-header-tint: #d83923;
  }
  
  .ion-color-poll-header {
    --ion-color-base: var(--ion-color-poll-header);
    --ion-color-base-rgb: var(--ion-color-poll-header-rgb);
    --ion-color-contrast: var(--ion-color-poll-header-contrast);
    --ion-color-contrast-rgb: var(--ion-color-poll-header-contrast-rgb);
    --ion-color-shade: var(--ion-color-poll-header-shade);
    --ion-color-tint: var(--ion-color-poll-header-tint);
  }
  
  .ion-color-ion-content-bg {
    --ion-color-base: var(--ion-color-ion-content-bg);
    --ion-color-base-rgb: var(--ion-color-ion-content-bg-rgb);
    --ion-color-contrast: var(--ion-color-ion-content-bg-contrast);
    --ion-color-contrast-rgb: var(--ion-color-ion-content-bg-contrast-rgb);
    --ion-color-shade: var(--ion-color-ion-content-bg-shade);
    --ion-color-tint: var(--ion-color-ion-content-bg-tint);
  }

  .ion-color-bg {
    --ion-color-base: var(--ion-color-bg);
    --ion-color-base-rgb: var(--ion-color-bg-rgb);
    --ion-color-contrast: var(--ion-color-bg-contrast);
    --ion-color-contrast-rgb: var(--ion-color-bg-contrast-rgb);
    --ion-color-shade: var(--ion-color-bg-shade);
    --ion-color-tint: var(--ion-color-bg-tint);
  }
  
  .ion-color-lightest {
    --ion-color-base: var(--ion-color-lightest);
    --ion-color-base-rgb: var(--ion-color-lightest-rgb);
    --ion-color-contrast: var(--ion-color-lightest-contrast);
    --ion-color-contrast-rgb: var(--ion-color-lightest-contrast-rgb);
    --ion-color-shade: var(--ion-color-lightest-shade);
    --ion-color-tint: var(--ion-color-lightest-tint);
  }
  
  .ion-color-highlight {
    --ion-color-base: var(--ion-color-highlight);
    --ion-color-base-rgb: var(--ion-color-highlight-rgb);
    --ion-color-contrast: var(--ion-color-highlight-contrast);
    --ion-color-contrast-rgb: var(--ion-color-highlight-contrast-rgb);
    --ion-color-shade: var(--ion-color-highlight-shade);
    --ion-color-tint: var(--ion-color-highlight-tint);
  }
  
  .ion-color-pro-badge {
    --ion-color-base: var(--ion-color-pro-badge);
    --ion-color-base-rgb: var(--ion-color-pro-badge-rgb);
    --ion-color-contrast: var(--ion-color-pro-badge-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pro-badge-contrast-rgb);
    --ion-color-shade: var(--ion-color-pro-badge-shade);
    --ion-color-tint: var(--ion-color-pro-badge-tint);
  }

  .ion-color-header-separator {
    --ion-color-base: var(--ion-color-header-separator);
    --ion-color-base-rgb: var(--ion-color-header-separator-rgb);
    --ion-color-contrast: var(--ion-color-header-separator-contrast);
    --ion-color-contrast-rgb: var(--ion-color-header-separator-contrast-rgb);
    --ion-color-shade: var(--ion-color-header-separator-shade);
    --ion-color-tint: var(--ion-color-header-separator-tint);
  }